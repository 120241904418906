import React, { useState, useEffect } from "react";
import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import { Container, Row, Col } from "react-bootstrap";

import { whoami, getAssessmentTypes } from "../services/APIClient";

import "../components/RightPanel.css";
import SchoolAssessmentReport from "../components/SchoolAssessmentReport";

function AssessmentReports(props) {
  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState("");
  const [teachers, setTeachers] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [teacherEmail, setTeacherEmail] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    whoami().then((response) => {
      const { schoolid, id, name, email } = response.data.stakeholder;
      setSchoolid(schoolid);
      setTeacherid(id);
      setName(name);
      setTeacherEmail(email);
      setIsLoading(false);
    });
  }, []);

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  return (
    <Container fluid className="vh-100">
      <HeadingBanner
        name={`Welcome ${name}!`}
        isLoggedIn={true}
        cloud={true}
        teacherid={teacherid}
      />
      <Row className="h-100">
        <LeftPanel />
        <Col sm={{ span: 10, offset: 2 }} className="blue-background">
          <SchoolAssessmentReport />
        </Col>
      </Row>
    </Container>
  );
}

export default AssessmentReports;
