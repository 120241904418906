import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Alert, ListGroup } from "react-bootstrap";
import {
  getTeachersBySchoolId,
  getClassesByTeacherId,
  removeFromSharedClass,
  removeOrReplaceClassOwner,
  replaceClassCreator
} from "../services/APIClient"; // Adjusted API call import

function RemoveTeacherFromClass({ show, onHide, schoolid }) {
  const [teachers, setTeachers] = useState([]); // Store teachers
  const [selectedTeacher, setSelectedTeacher] = useState(null); // Store the selected teacher
  const [classes, setClasses] = useState([]); // Store the classes that the selected teacher is part of
  const [statusMessage, setStatusMessage] = useState(""); // Status message for success/failure
  const [alertVariant, setAlertVariant] = useState(""); // Alert variant for Bootstrap

  const [showConfirmationModal, setShowConfirmationModal] = useState(false); // For the confirmation modal
  const [selectedClassForAction, setSelectedClassForAction] = useState(null); // The class selected for action
  const [newOwnerOrCreator, setNewOwnerOrCreator] = useState(""); // For assigning a new owner/creator if needed

  // Fetch teachers when the modal opens
  useEffect(() => {
    if (schoolid) {
      getTeachersBySchoolId(schoolid)
        .then((response) => {
          setTeachers(response); // Store the teachers
        })
        .catch((error) => {
          setStatusMessage("Error fetching teachers. Please try again.");
          setAlertVariant("danger");
        });
    }
  }, [schoolid]);

  // Fetch classes when a teacher is selected
  useEffect(() => {
    if (selectedTeacher) {
      getClassesByTeacherId(selectedTeacher)
        .then((response) => {
          const mergedClasses = mergeClasses(response);
          setClasses(mergedClasses); // Store the merged classes
        })
        .catch((error) => {
          setStatusMessage("Error fetching classes for the teacher. Please try again.");
          setAlertVariant("danger");
        });
    }
  }, [selectedTeacher]);

  // Helper function to merge duplicate classes and combine associations
  const mergeClasses = (classData) => {
    const classMap = {};

    classData.forEach(({ class: classItem, association }) => {
      const classId = classItem.id;

      if (classMap[classId]) {
        classMap[classId].association += `, ${association}`;
      } else {
        classMap[classId] = { ...classItem, association };
      }
    });

    return Object.values(classMap);
  };

  // Handle the red X click (show confirmation modal)
  const handleDeleteClassAssociation = (classItem) => {
    setSelectedClassForAction(classItem); // Set the class for action
    setShowConfirmationModal(true); // Show confirmation modal
  };

  // Handle confirmed deletion/removal based on association
  const confirmClassAction = () => {
    const { association } = selectedClassForAction;

    // 1. Handle the shared class association removal (if applicable)
    const sharedPromise = association.includes("shared")
      ? removeFromSharedClass(selectedClassForAction.id, selectedTeacher)
          .then(() => {
            setStatusMessage("Shared association removed successfully.");
            setAlertVariant("success");
          })
          .catch(() => {
            setStatusMessage("Failed to remove shared association.");
            setAlertVariant("danger");
          })
      : Promise.resolve();  // No shared association to remove

    // 2. Handle owner removal and replacement (if applicable)
    const ownerPromise = association.includes("owner")
      ? sharedPromise.then(() => {
          return removeOrReplaceClassOwner(selectedClassForAction.id, newOwnerOrCreator || "")
            .then(() => {
              setStatusMessage("Class owner changed successfully.");
              setAlertVariant("success");
            })
            .catch(() => {
              setStatusMessage("Failed to change class owner.");
              setAlertVariant("danger");
            });
        })
      : sharedPromise;  // Continue if there's no owner association

    // 3. Handle class creator replacement (if applicable)
    const creatorPromise = association.includes("created")
      ? ownerPromise.then(() => {
          return replaceClassCreator(selectedClassForAction.id, newOwnerOrCreator)
            .then(() => {
              setStatusMessage("Class creator replaced successfully.");
              setAlertVariant("success");
            })
            .catch(() => {
              setStatusMessage("Failed to replace class creator.");
              setAlertVariant("danger");
            });
        })
      : ownerPromise;  // Continue if there's no creator association

    // 4. Execute all promises in sequence
    creatorPromise.finally(() => {
      setClasses(classes.filter((cls) => cls.id !== selectedClassForAction.id));  // Update UI
      setShowConfirmationModal(false);
      setNewOwnerOrCreator(""); // Clear owner/creator selection
    });
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          onHide();
          setSelectedTeacher(null);
          setStatusMessage("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Removing Teacher from Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Dropdown for selecting a teacher */}
          <Form.Group>
            <Form.Label>Select Teacher to Remove:</Form.Label>
            <Form.Control
              as="select"
              value={selectedTeacher || ""}
              onChange={(e) => setSelectedTeacher(e.target.value)}
            >
              <option value="">Select Teacher</option>
              {teachers.map((teacher) => (
                <option key={teacher.id} value={teacher.id}>
                  {teacher.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {/* List of classes the selected teacher is part of */}
          {selectedTeacher && classes.length > 0 && (
            <div className="mt-4" style={{ maxHeight: "300px", overflowY: "auto" }}>
              <h5>Classes Associated with {teachers.find(t => t.id === selectedTeacher)?.name}</h5>
              <ListGroup>
                {classes.map((classItem) => (
                  <ListGroup.Item
                    key={classItem.id}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <div>
                      <div><strong>Class Name:</strong> {classItem.name}</div>
                      <div><strong>Grade Level:</strong> {classItem.grade_level}</div>
                      <div><strong>Trial Class:</strong> {classItem.trial_class ? 'Yes' : 'No'}</div>
                      <div><strong>Association:</strong> {classItem.association}</div>
                    </div>

                    <Button variant="danger" onClick={() => handleDeleteClassAssociation(classItem)}>
                      &#10006;
                    </Button>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          )}

          {/* Status message for success or failure */}
          {statusMessage && (
            <Alert variant={alertVariant} className="mt-3" dismissible onClose={() => setStatusMessage("")}>
              {statusMessage}
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      {selectedClassForAction && (
        <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Class Action</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to remove <strong>{teachers.find(t => t.id === selectedTeacher)?.name}</strong> from the class <strong>{selectedClassForAction.name}</strong>?</p>
            {selectedClassForAction.association.includes("owner") && (
              <>
                <p>This teacher is the class owner. Please assign a new owner:</p>
                <Form.Group>
                  <Form.Control
                    as="select"
                    value={newOwnerOrCreator || ""}
                    onChange={(e) => setNewOwnerOrCreator(e.target.value)}
                  >
                    <option value="">Select New Owner</option>
                    {teachers.map((teacher) => (
                      <option key={teacher.id} value={teacher.id}>
                        {teacher.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </>
            )}
            {selectedClassForAction.association.includes("created") && (
              <>
                <p>This teacher created the class. You must assign a new teacher:</p>
                <Form.Group>
                  <Form.Control
                    as="select"
                    value={newOwnerOrCreator || ""}
                    onChange={(e) => setNewOwnerOrCreator(e.target.value)}
                  >
                    <option value="">Select New Teacher</option>
                    {teachers.map((teacher) => (
                      <option key={teacher.id} value={teacher.id}>
                        {teacher.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>Cancel</Button>
            <Button variant="danger" onClick={confirmClassAction}>Confirm</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default RemoveTeacherFromClass;
