import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Accordion, Card, Button, Modal } from "react-bootstrap";
import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import OrientationPrompt from "../components/OrientationPrompt";
import SchoolRollNumberInput from "../components/SchoolRollNumberInput";
import { getClassesBySchoolId, getStudentsByClassId, getIndividualStudentResults, deleteAnswer } from "../services/APIClient"; // Import your API calls
import secondsToTime from "../services/secondsToTime"; // Import your API calls

function ResettingTasks() {
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );
  const [name, setName] = useState("User"); // Placeholder name for the HeadingBanner
  const [schoolid, setSchoolid] = useState(null); // Get the school ID from the SchoolRollNumberInput
  const [schoolName, setSchoolName] = useState("John Doe");
  
  // For dropdowns
  const [classes, setClasses] = useState([]); // Store the classes
  const [selectedClass, setSelectedClass] = useState(null); // Selected class
  const [students, setStudents] = useState([]); // Store the students
  const [selectedStudent, setSelectedStudent] = useState(null); // Selected student

  // For student results
  const [studentResults, setStudentResults] = useState([]);

  // For modal
  const [showModal, setShowModal] = useState(false); // Track if the modal is visible
  const [selectedTask, setSelectedTask] = useState(null); // Track the selected task for deletion

  // Handle device orientation
  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    setName("User");
  }, []);

  // Fetch classes when the school ID is retrieved
  useEffect(() => {
    if (schoolid) {
      getClassesBySchoolId(schoolid)
        .then((response) => {
          setClasses(response); // Store the classes
        })
        .catch((error) => {
          console.error("Error fetching classes:", error);
        });
    }
  }, [schoolid]);

  // Fetch students when a class is selected
  useEffect(() => {
    if (selectedClass) {
      getStudentsByClassId(selectedClass)
        .then((response) => {
          setStudents(response); // Store the students
        })
        .catch((error) => {
          console.error("Error fetching students:", error);
        });
    }
  }, [selectedClass]);

  // Fetch individual student results when a student is selected
  useEffect(() => {
    if (selectedStudent) {
      getIndividualStudentResults(selectedStudent)
        .then((response) => {
          setStudentResults(response); // Store the student results
          console.log("Student Results:", response);
        })
        .catch((error) => {
          console.error("Error fetching student results:", error);
        });
    }
  }, [selectedStudent]);

  // Calculate average score for tasks in an assessment
  const calculateAverageScore = (tasks) => {
    if (!tasks || tasks.length === 0) return 0;
    const totalScore = tasks.reduce((sum, task) => sum + task.score, 0);
    return (totalScore / tasks.length).toFixed(2);
  };

  // Handle showing the modal with the selected task
  const handleShowModal = (task) => {
    setSelectedTask(task); // Set the task to be deleted
    setShowModal(true); // Show the modal
  };

  // Handle deletion after confirmation and update the UI
  const handleDeleteAnswer = () => {
    if (selectedTask) {
      deleteAnswer(selectedTask.answerid)
        .then(() => {
          console.log(`Answer with ID: ${selectedTask.answerid} deleted successfully.`);
          
          // Update the UI by removing the deleted task from studentResults
          const updatedResults = studentResults.map((assessment) => {
            return {
              ...assessment,
              tasks: assessment.tasks.filter((task) => task.answerid !== selectedTask.answerid)
            };
          });

          setStudentResults(updatedResults); // Update the state to reflect the deletion
          setShowModal(false); // Hide the modal
          setSelectedTask(null); // Clear the selected task
        })
        .catch((error) => {
          console.error(`Error deleting answer with ID: ${selectedTask.answerid}`, error);
        });
    }
  };

  return (
    <div>
      <Container fluid className="vh-100">
        {/* HeadingBanner */}
        <HeadingBanner name={`Welcome ${name}!`} isLoggedIn={true} cloud={true} />

        <Row className="h-100">
          {/* LeftPanel */}
          <LeftPanel />

          {/* Main content */}
          <Col sm={{ span: 10, offset: 2 }} className="blue-background d-flex flex-column justify-content-start align-items-center">
            
            {/* SchoolRollNumberInput */}
            <div className="w-50 mt-4">
              <SchoolRollNumberInput
                onSchoolIdRetrieved={setSchoolid} // Set school ID from input
                schoolNameRetrieved={setSchoolName} // Set school name from input
              />
            </div>

            {/* Class Dropdown */}
            {schoolid && (
              <Form.Group className="mt-4 w-50">
                <Form.Label>Select Class</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedClass || ""}
                  onChange={(e) => setSelectedClass(e.target.value)}
                >
                  <option value="">Select Class</option>
                  {classes.map((cls) => (
                    <option key={cls.id} value={cls.id}>
                      {cls.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            {/* Student Dropdown */}
            {selectedClass && (
              <Form.Group className="mt-4 w-50">
                <Form.Label>Select Student</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedStudent || ""}
                  onChange={(e) => setSelectedStudent(e.target.value)}
                >
                  <option value="">Select Student</option>
                  {students.map((student) => (
                    <option key={student.id} value={student.id}>
                      {student.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            {/* Display Student Results in Accordion */}
            {studentResults.length > 0 && (
              <div className="w-75 mt-5">
                <h4>Student Assessments</h4>
                <Accordion defaultActiveKey="0">
                  {studentResults.map((assessment, idx) => (
                    <Accordion.Item eventKey={idx} key={assessment.id}>
                      <Accordion.Header>
                        <div className="d-flex justify-content-between w-100">
                          <span>{assessment.name}</span>
                          <span>Duration: {secondsToTime(assessment.tasks.reduce((total, task) => total + task.duration, 0))}</span>
                          <span>Avg. Score: {calculateAverageScore(assessment.tasks)}</span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {assessment.tasks.map((task, index) => (
                          <Card key={task.answerid} className="mb-3">
                            <Card.Body>
                              <div className="d-flex justify-content-between align-items-center">
                                {/* Red X Button */}
                                <span 
                                  style={{ color: 'red', cursor: 'pointer' }} 
                                  onClick={() => handleShowModal(task)}
                                >
                                  &#10006;
                                </span>
                                
                                <span>Task: {task.name}</span>
                                <span>Duration: {secondsToTime(task.duration)} seconds</span>
                                <span>Score: {(task.score * 100).toFixed(2)}</span>
                              </div>
                            </Card.Body>
                          </Card>
                        ))}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              </div>
            )}
          </Col>
        </Row>
      </Container>

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedTask && (
            <div>
              <p>Are you sure you want to delete the following task?</p>
              <ul>
                <li><strong>Task Name:</strong> {selectedTask.name}</li>
                <li><strong>Duration:</strong> {selectedTask.duration} seconds</li>
                <li><strong>Score:</strong> {(selectedTask.score * 100).toFixed(2)}</li>
              </ul>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteAnswer}>
            Confirm Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* OrientationPrompt for portrait mode */}
      {isPortrait && <OrientationPrompt />}
    </div>
  );
}

export default ResettingTasks;