import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import HeadingBanner from "../components/HeadingBanner";

import {
  whoami,
  getClasses,
  getTeachers,
  getSchools,
  getAllAssessmentInstances,
  postResultsByStudent,
} from "../services/APIClient";
import { Container, Row, Col } from "react-bootstrap";
import LeftPanel from "../components/LeftPanel";

import "../components/ClassCard.css";
import "../components/MasterDashCards.css";
import "../components/SuperSecretInternalDash.css";

import { exportToExcel } from "../services/ExcelExporter";
import secondsToTime from "../services/secondsToTime";

import AssessmentExport from "../components/AssessmentExport";
import GradeLevelExport from "../components/GradeLevelExport";

function Reports() {
  const [schoolid, setSchoolid] = useState(null);
  const [teacherid, setTeacherid] = useState(null);
  const [name, setName] = useState("");
  const [teachers, setTeachers] = useState(null);
  const [classes, setClasses] = useState([]);
  const [classid, setClassid] = useState([]);
  const [rollNumber, setRollNumber] = useState(null);
  const [teacherEmail, setTeacherEmail] = useState(null);
  const [allAssessmentInstances, setAllAssessmentInstances] = useState();
  const [inputRollNumber, setInputRollNumber] = useState("");
  const [schoolDetails, setSchoolDetails] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [notFoundMessage, setNotFoundMessage] = useState("");
  const [assessmentResultsByStudent, setAssessmentResultsByStudent] = useState(
    []
  );
  const [isDataReadyForExport, setIsDataReadyForExport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    whoami().then((response) => {
      const { schoolid, id, name, email } = response.data.stakeholder;
      setSchoolid(schoolid);
      setTeacherid(id);
      setName(name);
      setTeacherEmail(email);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!schoolid) return;
    setIsLoading(true); // Start loading
    Promise.all([getTeachers(schoolid), getClasses(), getSchools()])
      .then(([teachersResponse, classesResponse, schoolsResponse]) => {
        setTeachers(teachersResponse);
        setClasses(classesResponse);
        setClassid(classesResponse.id);

        const matchingSchool = schoolsResponse.data.find(
          (school) => school.id === schoolid
        );
        if (matchingSchool) {
          setRollNumber(matchingSchool.rollnumber);
        }
      })
      .finally(() => setIsLoading(false));
  }, [schoolid]);

  const handleRollNumberSubmit = async () => {
    try {
      setErrorMessage("");
      setNotFoundMessage("");
      const schoolsResponse = await getSchools();
      const school = schoolsResponse.data.find(
        (s) => s.rollnumber === inputRollNumber.toUpperCase()
      );
      school
        ? setSchoolDetails(school)
        : setNotFoundMessage("No school found with the provided roll number.");
    } catch (error) {
      setErrorMessage(
        "An error occurred while fetching school details: " + error.message
      );
    }
  };

  const handleAssessmentReportsNav = () => {
    navigate("/assessmentreports", {
      state: {
        teachers: { teachers },

        teacherid: { teacherid },
        schoolid: { schoolid },
      },
    });
  };

  useEffect(() => {
    if (!schoolDetails || !schoolDetails.rollnumber) return;

    getAllAssessmentInstances(schoolDetails.rollnumber)
      .then((response) => {
        setAllAssessmentInstances(response);
        const instanceIds = response.map((instance) => instance.id);
        return postResultsByStudent(instanceIds);
      })
      .then((studentResults) => setAssessmentResultsByStudent(studentResults))
      .catch((error) =>
        setErrorMessage("Error fetching data: " + error.message)
      );
  }, [schoolDetails]);

  console.log(allAssessmentInstances, "all assessment instances");

  const getAllAssessmentInstanceIds = () => {
    return allAssessmentInstances.map((instance) => instance.id);
  };

  useEffect(() => {
    if (allAssessmentInstances && allAssessmentInstances.length > 0) {
      const ids = getAllAssessmentInstanceIds();
      console.log(ids);
    }
  }, [allAssessmentInstances]);

  console.log(assessmentResultsByStudent);

  const assessmentInstanceMap = allAssessmentInstances?.reduce(
    (map, instance) => {
      map[instance.id] = instance;
      return map;
    },
    {}
  );

  const mergedData = assessmentResultsByStudent?.map((result) => {
    const instance = assessmentInstanceMap[result.assessmentInstance];
    return {
      ...result,
      ...instance, // Add fields from the corresponding assessment instance
    };
  });

  console.log(assessmentInstanceMap);

  console.log(mergedData);

  useEffect(() => {
    if (
      schoolDetails &&
      allAssessmentInstances &&
      assessmentResultsByStudent.length > 0
    ) {
      setIsDataReadyForExport(true);
    } else {
      setIsDataReadyForExport(false);
    }
  }, [schoolDetails, allAssessmentInstances, assessmentResultsByStudent]);

  // const transformDataForStudentExport = (data) => {
  //   if (!Array.isArray(data)) {
  //     console.error("Data is not an array:", data);
  //     return [];
  //   }

  //   const studentMap = new Map();

  //   data.forEach(item => {
  //     if (!Array.isArray(item.tasks)) {
  //       console.error("Tasks are not in array format for item:", item);
  //       return; // Skip this iteration
  //     }

  //     if (!studentMap.has(item.studentid)) {
  //       studentMap.set(item.studentid, {
  //         studentName: item.student.name,
  //         className: item.className,
  //         tasks: new Map()
  //       });
  //     }

  //     const studentRecord = studentMap.get(item.studentid);

  //     item.tasks.forEach(task => {
  //       if (!studentRecord.tasks.has(task.name)) {
  //         studentRecord.tasks.set(task.name, []);
  //       }
  //       studentRecord.tasks.get(task.name).push(task.score);
  //     });
  //   });

  //   // Debugging: Log the tasks for each student
  //   studentMap.forEach((student, studentId) => {
  //     console.log(`Tasks for student ${studentId}:`, student.tasks);
  //   });

  //   return Array.from(studentMap.values()).map(student => {
  //     const taskScores = {};
  //     student.tasks.forEach((scores, taskName) => {
  //       // Ensure each task name is a valid key in the resulting object
  //       taskScores[`Task: ${taskName}`] = scores.join(", ");
  //     });

  //     return {
  //       "Student Name": student.studentName,
  //       "Class Name": student.className,
  //       ...taskScores
  //     };
  //   });
  // };

  // const handleExportClickStudent = () => {
  //   try {
  //     const exportData = transformDataForStudentExport(mergedData);

  //     console.log("Prepared data for export:", exportData);

  //     if (!Array.isArray(exportData) || exportData.length === 0) {
  //       console.error("No data available for export or data is not in the correct format:", exportData);
  //       return;
  //     }

  //     exportToExcel(exportData, "StudentData");
  //   } catch (error) {
  //     console.error("Error during export:", error);
  //   }
  // };

  // excel for class
  // function sanitizeClassName(className) {
  //   return className
  //     .replace(/[:\\\/\?\*\[\]]/g, '') // Remove disallowed characters
  //     .slice(0, 31); // Truncate to 31 characters
  // }

  // const groupedByClassName = mergedData?.reduce((groups, item) => {
  //   const className = item.className;
  //   // Ensure class names are unique and Excel-friendly here
  //   const sanitizedClassName = sanitizeClassName(className);
  //   groups[sanitizedClassName] = groups[sanitizedClassName] || [];
  //   groups[sanitizedClassName].push(item);
  //   return groups;
  // }, {});

  // console.log(groupedByClassName)

  //  const handleExportClick = () => {
  //   const sheetsData = Object.keys(groupedByClassName).reduce((sheets, className) => {
  //     const transformedData = transformDataForExcel(groupedByClassName[className]);
  //     sheets[className] = transformedData;
  //     return sheets;
  //   }, {});

  //   exportToExcel(sheetsData, "SchoolData");
  // };

  // const groupByGradeLevel = (data) => {
  //   return data?.reduce((grouped, item) => {
  //     let gradeLevel = 'Grade Level Unknown'; // default if no grade level is set
  //     console.log(item)
  //     if (item.gradeLevel1) {
  //       gradeLevel = 'Grade Level 1';
  //     } else if (item.gradeLevel2) {
  //       gradeLevel = 'Grade Level 2';
  //     }
  //     // ... add more conditions for other grade levels if needed

  //     grouped[gradeLevel] = grouped[gradeLevel] || [];
  //     grouped[gradeLevel].push(item);
  //     return grouped;
  //   }, {});
  // };

  // console.log(groupByGradeLevel())

  // function sanitizeName(name) {
  //   return name
  //     .replace(/[:\\\/\?\*\[\]]/g, '') // Remove disallowed characters
  //     .slice(0, 31); // Truncate to 31 characters
  // }

  // const groupByAssessmentName = (data) => {
  //   return data.reduce((grouped, item) => {
  //     const assessmentName = sanitizeName(item.name);
  //     grouped[assessmentName] = grouped[assessmentName] || [];
  //     grouped[assessmentName].push(item);
  //     return grouped;
  //   }, {});
  // };

  // const transformDataForExcel = (data) => {
  //   return data.map(item => {
  //     const transformedItem = {
  //       "Student Name": item.student.name,
  //       "At Risk": item.atRisk, // Assuming 'atRisk' is a property
  //       "Time": item.time,
  //       "Class Name": item.className
  //     };

  //     // Calculate reading score
  //     let totalScore = 0;
  //     item.tasks.forEach(task => {
  //       totalScore += task.score;
  //       transformedItem[task.name] = task.score; // Add task score
  //     });
  //     const readingScore = item.tasks.length > 0 ? totalScore / item.tasks.length : 0;
  //     transformedItem["Reading Score"] = readingScore;

  //     return transformedItem;
  //   });
  // };

  // const transformDataForExport = () => {
  //   const studentsData = [];
  //   const allTasks = new Set();

  //   // Assuming mergedData combines assessment results with class and student info
  //   const mergedData = assessmentResultsByStudent.map(result => {
  //     const instance = assessmentInstanceMap[result.assessmentInstance];
  //     return {
  //       ...result,
  //       ...instance // Add fields from the corresponding assessment instance
  //     };
  //   });

  //   mergedData.forEach(studentResult => {
  //     studentResult.tasks.forEach(task => {
  //       allTasks.add(task.name);
  //     });
  //   });

  //   const allTasksArray = Array.from(allTasks);

  //   mergedData.forEach(studentResult => {
  //     const taskScores = studentResult.tasks.map(task => task.score);
  //     const readingScore = taskScores.reduce((a, b) => a + b, 0) / taskScores.length;
  //     const readingScoreFormatted = (typeof readingScore === "number" && !isNaN(readingScore))
  //       ? `${(readingScore * 100).toFixed(2)}%` : "Not Started";

  //     const atRisk = readingScore <= 0.2 ? "Red Flag" : readingScore <= 0.4 ? "Yellow Flag" : "";
  //     const time = (typeof studentResult.time === "number" && !isNaN(studentResult.time))
  //       ? secondsToTime(studentResult.time) : "Not Started";

  //     let studentEntry = {
  //       "Student Name": studentResult.student.name,
  //       "Reading Score": readingScoreFormatted,
  //       "At-Risk": atRisk,
  //     };

  //     allTasksArray.forEach(taskName => {
  //       studentEntry[taskName] = "Partial";
  //     });

  //     studentResult.tasks.forEach(task => {
  //       studentEntry[task.name] = (task.score * 100).toFixed(2) + "%";
  //     });

  //     studentEntry["Time"] = time;
  //     studentEntry["Completed"] = studentResult.completed ? "TRUE" : "FALSE";
  //     studentEntry["Class"] = studentResult.className;

  //     studentsData.push(studentEntry);
  //   });

  //   // Sort the students by reading score
  //   studentsData.sort((a, b) => {
  //     let readingScoreA = 0, readingScoreB = 0;
  //     if (a["Reading Score"] && typeof a["Reading Score"] === 'string') {
  //       readingScoreA = parseFloat(a["Reading Score"].replace('%', ''));
  //     }
  //     if (b["Reading Score"] && typeof b["Reading Score"] === 'string') {
  //       readingScoreB = parseFloat(b["Reading Score"].replace('%', ''));
  //     }
  //     return readingScoreB - readingScoreA;
  //   });

  //   return studentsData;
  // };

  return (
    <div>
      <Container fluid className="vh-100">
        <HeadingBanner
          name={`Welcome ${name}!`}
          isLoggedIn={true}
          cloud={true}
          teacherid={teacherid}
        />
        <Row className="h-100">
          <LeftPanel />
          <Col sm={{ span: 10, offset: 2 }} className="blue-background">
            <div className="centered-form">
              <div className="input-button-group">
                <input
                  type="text"
                  value={inputRollNumber}
                  onChange={(e) =>
                    setInputRollNumber(e.target.value.toUpperCase())
                  }
                  placeholder="Enter Roll Number"
                  className="input-field-secret"
                />
                <button
                  className="yellow-button"
                  onClick={handleRollNumberSubmit}
                >
                  Submit
                </button>
              </div>
              <div>
                <button
                  className="blue-button"
                  onClick={handleAssessmentReportsNav}
                >
                  Assessment Reports
                </button>
              </div>

              {notFoundMessage && (
                <div className="message-box error-box">{notFoundMessage}</div>
              )}
              {errorMessage && (
                <div className="message-box error-box">{errorMessage}</div>
              )}

              {isLoading ? (
                <div className="loading-indicator">Loading...</div>
              ) : (
                schoolDetails &&
                isDataReadyForExport && (
                  <div className="message-box">
                    <p>School Name: {schoolDetails.name}</p>
                    <p>Roll Number: {schoolDetails.rollnumber}</p>
                    <p>Area: {schoolDetails.area}</p>
                    <AssessmentExport
                      schoolDetails={schoolDetails}
                      assessmentResultsByStudent={assessmentResultsByStudent}
                      allAssessmentInstances={allAssessmentInstances}
                      mergedData={mergedData}
                      assessmentInstanceMap={assessmentInstanceMap}
                    />
                    <GradeLevelExport
                      schoolDetails={schoolDetails}
                      assessmentResultsByStudent={assessmentResultsByStudent}
                      allAssessmentInstances={allAssessmentInstances}
                      mergedData={mergedData}
                      assessmentInstanceMap={assessmentInstanceMap}
                    />
                    {/* <button className="green-button" onClick={handleExportClickStudent}>Export Data by Student</button> */}
                  </div>
                )
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Reports;
