import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, Button, Card } from "react-bootstrap";
import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import OrientationPrompt from "../components/OrientationPrompt";
import SchoolRollNumberInput from "../components/SchoolRollNumberInput";
import SwapStudentsAssessmentModal from "../components/SwapStudentsAssessmentModal";
import DeleteClassesModal from "../components/DeleteClassesModal";
import DeleteStudentsModal from "../components/DeleteStudentsModal";
import StudentTransfersModal from "../components/StudentTransfersModal";
import RemoveTeacherFromClass from "../components/RemoveTeacherFromClass";
import "../components/ClassCard.css";

import {
  whoami,
  getClasses,
  getTeachers,
  getSchools,
  getCountryGrades,
  shareClass,
  swapAssessmentStudents,
  softDeleteClass,  
} from "../services/APIClient";


function ClassAdmin() {
  const [teacherid, setTeacherid] = useState(101);
  const [name, setName] = useState("John Doe");
  const [schoolid, setSchoolid] = useState("John Doe");
  const [schoolName, setSchoolName] = useState("Please enter school rollnumber and press 'Find School'");
  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

  const [showModal, setShowModal] = useState({
    removeTeacherFromClass: false,
    swapStudents: false,
    createClasses: false,
    deleteClasses: false,
    addingStudents: false,
    deletingStudents: false,
    sendingLogins: false,
    studentTransfers: false,
    jiToSiManagement: false,
    repeatingStudents: false,
  });

  const handleOpenModal = (modalName) => {
    setShowModal((prev) => ({ ...prev, [modalName]: true }));
  };

  const handleCloseModal = (modalName) => {
    setShowModal((prev) => ({ ...prev, [modalName]: false }));
  };


  useEffect(() => {
    whoami().then((response) => {
      console.log("response:", response);
      // setSchoolid(response.data.stakeholder.schoolid);
      setTeacherid(response.data.stakeholder.id);
      setName(response.data.stakeholder.name);
      // setTeacherRoles(response.data.stakeholder.additionalRoles);
    });
  }, []);

  return (
    <div>
      <Container fluid className="vh-100">
        <HeadingBanner name={`Welcome ${name}!`} isLoggedIn={true} cloud={true} teacherid={teacherid} />

        <Row className="h-100">
          <LeftPanel />
          <Col sm={{ span: 10, offset: 2 }} className="blue-background d-flex flex-column justify-content-center align-items-center">

            <SchoolRollNumberInput
              onSchoolIdRetrieved={setSchoolid} // Set school ID from input
              schoolNameRetrieved={setSchoolName} // Set school ID from input
            />

            <h2>{schoolName}</h2>

            {/* Row with 8 Cards */}
            <Row className="justify-content-center mt-4 w-100">
              <Col sm={3} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('removeTeacherFromClass')}>
                  <p className="class-card-text">Remove Teacher from Class</p>
                </Card>
              </Col>
              <Col sm={3} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('swapStudents')}>
                  <p className="class-card-text">Swapping Students Assessments</p>
                </Card>
              </Col>
              <Col sm={3} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('createClasses')}>
                  <p className="class-card-text">Create Classes</p>
                </Card>
              </Col>
              <Col sm={3} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('deleteClasses')}>
                  <p className="class-card-text">Delete Classes</p>
                </Card>
              </Col>
              <Col sm={3} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('addingStudents')}>
                  <p className="class-card-text">Adding Students</p>
                </Card>
              </Col>
              <Col sm={3} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('deletingStudents')}>
                  <p className="class-card-text">Deleting Students</p>
                </Card>
              </Col>
              <Col sm={3} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('sendingLogins')}>
                  <p className="class-card-text">Sending Student Logins without QR Codes</p>
                </Card>
              </Col>
              <Col sm={3} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('studentTransfers')}>
                  <p className="class-card-text">Student Transfers Between Classes</p>
                </Card>
              </Col>
              <Col sm={3} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('jiToSiManagement')}>
                  <p className="class-card-text">JI to SI Class Management</p>
                </Card>
              </Col>
              <Col sm={3} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('repeatingStudents')}>
                  <p className="class-card-text">Repeating Students</p>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {isPortrait && <OrientationPrompt />}

      <RemoveTeacherFromClass
        show={showModal.removeTeacherFromClass}
        onHide={() => handleCloseModal('removeTeacherFromClass')}
        schoolid={schoolid}
        swapAssessmentStudents={swapAssessmentStudents} // Pass the swap function to the modal
      />

      {/* Modals for each card */}
      <SwapStudentsAssessmentModal
        show={showModal.swapStudents}
        onHide={() => handleCloseModal('swapStudents')}
        schoolid={schoolid}
        swapAssessmentStudents={swapAssessmentStudents} // Pass the swap function to the modal
      />

      <Modal show={showModal.createClasses} onHide={() => handleCloseModal('createClasses')}>
        <Modal.Header closeButton>
          <Modal.Title>Create Class</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for Create.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal('createClasses')}>Close</Button>
        </Modal.Footer>
      </Modal>

      <DeleteClassesModal
        show={showModal.deleteClasses}
        onHide={() => handleCloseModal('deleteClasses')}
        schoolid={schoolid}
        softDeleteClass={softDeleteClass} // Pass the swap function to the modal
      />

      <Modal show={showModal.addingStudents} onHide={() => handleCloseModal('addingStudents')}>
        <Modal.Header closeButton>
          <Modal.Title>Adding Students</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for Adding Students.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal('addingStudents')}>Close</Button>
        </Modal.Footer>
      </Modal>

      <DeleteStudentsModal
        show={showModal.deletingStudents}
        onHide={() => handleCloseModal('deletingStudents')}
        schoolid={schoolid}
      />

      <Modal show={showModal.sendingLogins} onHide={() => handleCloseModal('sendingLogins')}>
        <Modal.Header closeButton>
          <Modal.Title>Sending Student Logins without QR Codes</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for Sending Student Logins without QR Codes.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal('sendingLogins')}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal.studentTransfers} onHide={() => handleCloseModal('studentTransfers')}>
        <Modal.Header closeButton>
          <Modal.Title>Student Transfers Between Classes</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for Student Transfers Between Classes.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal('studentTransfers')}>Close</Button>
        </Modal.Footer>
      </Modal>

      <StudentTransfersModal
        show={showModal.studentTransfers}
        onHide={() => handleCloseModal('studentTransfers')}
        schoolid={schoolid}
      />

      <Modal show={showModal.jiToSiManagement} onHide={() => handleCloseModal('jiToSiManagement')}>
        <Modal.Header closeButton>
          <Modal.Title>JI to SI Class Management</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for JI to SI Class Management.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal('jiToSiManagement')}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal.repeatingStudents} onHide={() => handleCloseModal('repeatingStudents')}>
        <Modal.Header closeButton>
          <Modal.Title>Repeating Students</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for Repeating Students.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal('repeatingStudents')}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ClassAdmin;
