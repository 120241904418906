import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Button, Card } from "react-bootstrap";
import HeadingBanner from "../components/HeadingBanner";
import LeftPanel from "../components/LeftPanel";
import OrientationPrompt from "../components/OrientationPrompt";
import SchoolRollNumberInput from "../components/SchoolRollNumberInput";
import RemovingTeacherModal from "../components/RemovingTeacherModal";
import "../components/ClassCard.css";

import {
  whoami,
  getClasses,
  getTeachers,
  getSchools,
  getCountryGrades,
  shareClass,
} from "../services/APIClient";

function SchoolAdmin() {
  const [teacherid, setTeacherid] = useState(101);
  const [name, setName] = useState("John Doe");
  const [schoolid, setSchoolid] = useState("John Doe");
  const [schoolName, setSchoolName] = useState("John Doe");
  const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

  const [showModal, setShowModal] = useState({
    removingTeacher: false,
    addingTeacher: false,
    changingAlpacaLead: false,
    rolesPermissions: false,
    sendForgottenPassword: false,
    viewMetrics: false,
  });

  useEffect(() => {
    whoami().then((response) => {
      console.log("response:", response);
      setTeacherid(response.data.stakeholder.id);
      setName(response.data.stakeholder.name);
      // setTeacherRoles(response.data.stakeholder.additionalRoles);
    });
  }, []);

  const handleOpenModal = (modalName) => {
    setShowModal((prev) => ({ ...prev, [modalName]: true }));
  };

  const handleCloseModal = (modalName) => {
    setShowModal((prev) => ({ ...prev, [modalName]: false }));
  };

  return (
    <div>
      <Container fluid className="vh-100">
        <HeadingBanner name={`Welcome ${name}!`} isLoggedIn={true} cloud={true} teacherid={teacherid} />

        <Row className="h-100">
          <LeftPanel />

          <Col sm={{ span: 10, offset: 2 }} className="blue-background d-flex flex-column justify-content-center align-items-center">
            <SchoolRollNumberInput
              onSchoolIdRetrieved={setSchoolid} // Set school ID from input
              schoolNameRetrieved={setSchoolName} // Set school ID from input
            />
            {/* Row with 6 Cards */}
            <Row className="justify-content-center mt-4 w-100">
              <Col sm={4} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('removingTeacher')}>
                  <p className="class-card-text">Removing Teacher from School</p>
                </Card>
              </Col>
              <Col sm={4} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('addingTeacher')}>
                  <p className="class-card-text">Adding Teachers</p>
                </Card>
              </Col>
              <Col sm={4} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('changingAlpacaLead')}>
                  <p className="class-card-text">Changing ALPACA Lead</p>
                </Card>
              </Col>
              <Col sm={4} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('rolesPermissions')}>
                  <p className="class-card-text">Roles and Permissions Management</p>
                </Card>
              </Col>
              <Col sm={4} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('sendForgottenPassword')}>
                  <p className="class-card-text">Send Forgotten Password Email</p>
                </Card>
              </Col>
              <Col sm={4} className="d-flex justify-content-center mb-3">
                <Card className="masterdash-card text-center p-3" onClick={() => handleOpenModal('viewMetrics')}>
                  <p className="class-card-text">View School Metrics</p>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      {isPortrait && <OrientationPrompt />}

      {/* Modals for each card */}
      {/* <Modal show={showModal.removingTeacher} onHide={() => handleCloseModal('removingTeacher')}>
        <Modal.Header closeButton>
          <Modal.Title>Removing Teacher from School</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for removing a teacher from the school.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal('removingTeacher')}>Close</Button>
        </Modal.Footer>
      </Modal> */}

      <RemovingTeacherModal
        show={showModal.removingTeacher}
        onHide={() => handleCloseModal('removingTeacher')}
        schoolid={schoolid}
      />

      <Modal show={showModal.addingTeacher} onHide={() => handleCloseModal('addingTeacher')}>
        <Modal.Header closeButton>
          <Modal.Title>Adding Teachers</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for adding teachers.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal('addingTeacher')}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal.changingAlpacaLead} onHide={() => handleCloseModal('changingAlpacaLead')}>
        <Modal.Header closeButton>
          <Modal.Title>Changing ALPACA Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for changing ALPACA lead.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal('changingAlpacaLead')}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal.rolesPermissions} onHide={() => handleCloseModal('rolesPermissions')}>
        <Modal.Header closeButton>
          <Modal.Title>Roles and Permissions Management</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for roles and permissions management.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal('rolesPermissions')}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal.sendForgottenPassword} onHide={() => handleCloseModal('sendForgottenPassword')}>
        <Modal.Header closeButton>
          <Modal.Title>Send Forgotten Password Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for sending forgotten password emails.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal('sendForgottenPassword')}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal.viewMetrics} onHide={() => handleCloseModal('viewMetrics')}>
        <Modal.Header closeButton>
          <Modal.Title>View School Metrics</Modal.Title>
        </Modal.Header>
        <Modal.Body>Content for viewing school metrics.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCloseModal('viewMetrics')}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SchoolAdmin;
